<template>
  <v-container fluid>
    <v-toolbar color="indigo lighten-5" flat>
      <BackButton />
      <v-toolbar-title>Події пристроїв</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <Logs hide-company />
  </v-container>
</template>

<script>
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'DeviceEventsList',

  components: {
    BackButton,
    Logs: () => import('@/components/common/Logs'),
  },

  data: () => ({
    search: '',
  }),
}
</script>

<style scoped></style>
